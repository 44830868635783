body {
  margin: 0;
  font-family: var(--type-first);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

iframe#webpack-dev-server-client-overlay{display:none!important}

.tippy-popper {
  height: fit-content;
}

input[type='date'] {
  position: relative;
}

/* make the native arrow invisible and stretch it over the whole field so you can click anywhere in the input field to trigger the native datepicker*/
input[type='date']::-webkit-calendar-picker-indicator {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: auto;
  height: auto;
  color: transparent;
  background: transparent;
}

/* adjust increase/decrease button */
input[type='date']::-webkit-inner-spin-button {
  z-index: 1;
}

/* adjust clear button */
input[type='date']::-webkit-clear-button {
  z-index: 1;
}

/* remover placeholder padrão */
/* input::-webkit-datetime-edit-text {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  display: none;
}
input::-webkit-datetime-edit-month-field {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  display: none;
}
input::-webkit-datetime-edit-day-field {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  display: none;
}
input::-webkit-datetime-edit-year-field {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  display: none;
} */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
